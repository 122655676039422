// @ts-ignore
import jwt, { JwtPayload } from 'jsonwebtoken';

export interface UserInt {
  username: string;
  nickname: string;
  email: string;
  isSuperuser: boolean;
  iat: number;
  exp: number;
  token: string;
  avatarURL?: string;
  permissions?: string[];
}

class User {
  public decodedUser: UserInt | JwtPayload;
  static KEY = 'userToken';
  constructor(public token: string, publicKey: string) {
    this.decodedUser = this.decodeUserToken(publicKey) as UserInt;
  }

  private decodeUserToken(publicKey: string) {
    try {
      return jwt.verify(this.token, publicKey);
    } catch (error) {
      User.logoutUser();
    }
  }
  static loadUserToken() {
    return localStorage.getItem(User.KEY) || sessionStorage.getItem(User.KEY);
  }

  static persistUser(token: string) {
    localStorage.setItem(User.KEY, typeof token === 'string' ? token : '');
  }

  static persistSession(token: string) {
    sessionStorage.setItem(User.KEY, typeof token === 'string' ? token : '');
  }

  static logoutUser() {
    localStorage.removeItem(User.KEY);
    sessionStorage.removeItem(User.KEY);
  }

  static expireUserSession(
    triggerMessageHandler?: (message: string, hasEror: boolean) => void
  ) {
    if (triggerMessageHandler) {
      triggerMessageHandler(
        'Your session has expired, please, login again!',
        true
      );
    }
    User.logoutUser();
  }
}

export default User;
