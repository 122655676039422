import { Link } from 'react-router-dom';
import styles from './GuestModeLabel.module.scss';

function GuestModeLabel() {
  return (
    <div className={styles['guest-mode-label-wrapper']}>
      You are viewing a guest version of the portal. Login{' '}
      <Link to="/auth">here</Link> to view the full version!
    </div>
  );
}

export default GuestModeLabel;
