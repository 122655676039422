import axios from // attachTokenInterceptor,
'../../../utils/api/axiosInstance';

export async function refreshToken(path: string, token: string) {
  try {
    // attachTokenInterceptor(token);
    const response = await axios.get<{ token: string }>(path);
    // attachTokenInterceptor(response.data.token);
    return response.data.token;
  } catch (error) {
    throw error;
  }
}
