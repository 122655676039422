export function sortPortalsByPosition(
  a: { portalPositionOrder: number | null },
  b: { portalPositionOrder: number | null }
) {
  return (
    // @ts-ignore
    (b.portalPositionOrder != null) - (a.portalPositionOrder != null) ||
    // @ts-ignore
    a.portalPositionOrder - b.portalPositionOrder
  );
}

export function truncateString(str: string, length: number) {
  if (str.length > length) {
    return str.slice(0, length) + '...';
  } else return str;
}
